/*explain：;@author: cy; @version: 1.0; date: 2022/4/18;*/
import axios from '@/api/axios';
import alert from '@/components/KAlert';
import httpConfig from '@/config/http.config';
function exportEntity(url, data, timeout = 60000) {
  url = httpConfig.url + url;
  return new Promise((resolve, reject) => {
    axios()
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Download-Request': 'Download-Request',
        },
        timeout: timeout,
        responseType: 'blob',
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export async function easyPoiExcel(url, fileName, params, timeout) {
  await exportEntity(url, params, timeout)
    .then((res) => {
      // 将文件流转成blob形式
      const blob = new Blob([res.data], {
        type: 'application/octet-stream;charset=utf-8',
      });
      // const blob = res.data;
      let filename = fileName + '.xls';
      // 创建一个超链接，将文件流赋进去，然后实现这个超链接的单击事件
      const elink = document.createElement('a');
      elink.download = filename;
      elink.style.display = 'none';
      elink.href = URL.createObjectURL(blob);
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href); // 释放URL 对象
      document.body.removeChild(elink);
    })
    .catch((err) => {
      console.log('导出失败', err);
      alert.error('导出失败');
    });
  return true;
}
export function easyInportExcel(url, params) {
  return axios({
    url: 'url',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'post',
    data: params,
  });
}
export function exportAccount(data, fileName) {
  return easyPoiExcel('/upms/user/export-excel', fileName, data);
}
export function exportAccountTemp(data, fileName) {
  return easyPoiExcel('/upms/user/excel-template', fileName, data);
}
